import React from 'react'

export const Ads = () => {
  return (
   <div >
     <div className='bg-[#fd8700] py-2  w-full '>
        <h3 className='px-4 text-lg font-semibold text-center text-white lg:text-xl'>Contact us: <a href="tel: 0424 628 959">0424 628 959</a></h3>
    </div>
   </div>
  )
}
