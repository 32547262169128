import React from "react";
import testimonialPhoto1 from "../Assets/Images/Testimonial2.png";
import testimonialPhoto2 from "../Assets/Images/Testimonial1.png";
import phoneBook from '../Assets/Images/phonebook.png';

export const Testimonial = () => {
  return (
    <div className="min-h-screen py-8 lg:py-14">

      {/* heading */}
      <h5 className="uppercase text-lg lg:text-center px-2 text-[#fd8700] ">
        CLIENT TESTIMONAILS
      </h5>
      <h1 className="px-2 text-5xl font-medium text-black uppercase lg:font-semibold lg:text-center lg:text-7xl">
      What Our Customers Are Saying <br /> About KJM Construction
      </h1>

      {/* testimonial box */}
      <div className="flex flex-col items-center justify-between gap-4 px-2 py-8 lg:flex-row lg:gap-12 lg:py-16 lg:px-28">
        <div className="flex basis-1/2 flex-col justify-center items-center bg-[#181818] py-8 px-8">
          <div>
            <img src={testimonialPhoto1} className="w-20 py-2 rounded-full" alt="" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="py-4 text-base text-white ">
            I recently hired KJM Construction for a retaining wall project and I am thoroughly impressed with the work done by Kane and his team. From start to finish, they were professional, reliable and stayed within budget. They arrived on time every day and left the site clean and tidy. The quality of their workmanship is outstanding, and I am extremely happy with the end result. I highly recommend KJM Construction to anyone looking for a trustworthy and experienced civil construction company.
            </p>
            <p className="text-[#fd8700]">Patrick Moore</p>
            <p className="text-white">Residential</p>
          </div>
        </div>
        <div className="flex basis-1/2 flex-col  justify-center items-center bg-[#181818] py-8 px-8">
          <div>
            <img src={testimonialPhoto2} className="w-20 py-2 rounded-full" alt="" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="pt-2 text-base text-white pb-7 ">
            Kane and his team at KJM Construction are amazing! We hired them for a stormwater project and they exceeded our expectations in every way. Their attention to detail and professionalism is second to none. They kept us informed throughout the entire project and completed it on time and within budget.
            </p>
            <p className="text-[#fd8700] pt-12">Han Seoul</p>
            <p className="text-sm text-white">ACTIONPOLY & CIVIL</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center bg-[#181818] py-4 px-4 w-fit mx-auto">
        <img src={phoneBook} className='w-12 lg:w-20' alt="img" /> <p className="pl-4 text-xl font-semibold text-[#fd8700] lg:text-3xl">For all quotes call Kane Morley <a href="tel:0424 628 959"><br /> - <small className="text-xl font-semibold text-white lg:text-3xl">0424 628 959</small></a></p>
      </div>
    </div>
  );
};
