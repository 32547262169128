import React from "react";
import getKnowImage from "../Assets/Images/get-to-know.jpg";

export const GetToKnow = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-12 py-4 lg:items-center lg:justify-between lg:gap-4 lg:flex-row lg:px-36">
      {/* image part */}
      <div className="basis-1/2">
        <img src={getKnowImage} className="lg:w-[700px] w-full pt-2" alt="get-to-know" />
      </div>

      {/* description part */}
      <div className="px-4 basis-1/2">
        <small className="uppercase text-lg text-[#fd8700]">About KJM Construction</small>
        <h1 className="text-5xl font-semibold text-black uppercase lg:text-7xl">
        Building The Future of SA
        </h1>
        <p className="py-4 text-lg lg:py-8">
        KJM Construction is a leading civil construction company in South Australia with over 15 years of experience. They are known for their expertise in building retaining walls, handling stormwater, and undertaking residual work. The company also specializes in developing new estates. Their commitment to quality workmanship and customer satisfaction has helped them establish a strong reputation in the industry. With KJM Construction, you can expect nothing but the best.
        </p>
       
      </div>
    </div>
  );
};
